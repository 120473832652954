<template>
	<div class="jobhunting">
		<div class="login">
			<div class="contain">
				<div class="right">
					<!-- 切换 -->
					<div class="mode mode1" @click="smLOGIN" v-if="sm">
						<img src="../assets/img/dn.png" alt="Alternate Text" />
						<div class="modeText">
							账号密码登录
						</div>
					</div>
					<div class="mode mode1" @click="yzmLOGIN" v-if="yzm">
						<img src="../assets/img/erwm.png" alt="Alternate Text" />
						<div class="modeText">
							微信扫码登录
						</div>
					</div>
					<div class="mode">
						<div @click="goto('/loginc')" style="font-size: 14px;color: #666;">我要招聘</div>
					</div>
					<!-- 扫描 -->
					<div v-if="sm">
						<div class="scanning">
							<div class="title">扫码登录</div>
							<div class="qrcode" ref="qrCodeUrl"></div>
							<div>使用<b>【微信扫一扫】</b><br>功能扫码登录</div>
						</div>
					</div>
					<!-- 电脑 -->
					<div v-show="yzm">
						<!-- <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
							<el-tab-pane label="登录/注册" name="first"></el-tab-pane>
							<el-tab-pane label="密码登录" name="second"></el-tab-pane>
						</el-tabs> -->
						<!-- 密码 -->
						<div v-if="activeName == 'first'">
							<div class="textIn">
								<div class="line">
									<i class="el-icon-mobile-phone"></i>
									<input type="text" v-model="subfrom.mobile" placeholder="手机号码" />
								</div>
								<div class="line" style="width:214px;float: left;">
									<i class="el-icon-unlock"></i>
									<input type="text" v-model="subfrom.code" placeholder="请输入验证码" />
								</div>
								<div class="smsbtn" @click="send()">发送验证码</div>
								<div class="clear"></div>
								<div class="btn" @click="logininfo()">登录/注册</div>

								<div class="free">
									<label for="cbxbox">
										<input id="cbxbox" type="checkbox" />
										我已阅读并同意
									</label>
									<a @click="goto('/info?id=12923')">《临海人才网用户服务协议》</a>
								</div>
							</div>
						</div>
						<!-- 短信 -->
						<div v-if="activeName == 'second'">
							<div class="textIn">
								<div class="line">
									<input type="text" v-model="subfrom.mobile" placeholder="请输入用户名" />
								</div>
								<div class="line">
									<input type="password" v-model="subfrom.password" placeholder="请输入密码" />
								</div>
								<div class="line">
									<input type="text" v-model="subfrom.code" placeholder="请输入验证码" />
									<span id="canvasCode" class="code"></span>
								</div>
								<div class="free">
									<el-checkbox v-model="cbxbox">记住我</el-checkbox>
								</div>
								<div class="btn" @click="logininfo()">登录</div>
								<div class="free">
									<a><!-- 忘记密码 --></a>
									<a @click="goto('/register')">注册账户</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="left">
					<div class="logo"><img src="../assets/img/login1.png" alt="" /></div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		UserLogin,
		CompanyLogin,
		Getscanuserinfo
	} from "@/api/user";
	import {
		getGVerify
	} from "@/api/home";
	import {
		isnull
	} from "@/assets/js/index";
	import QRCode from "qrcodejs2";
	import global from "@/assets/js/globalconfig";
	import { mapMutations,mapState} from 'vuex' // 引入useStore 方法
	export default {
		data() {
			return {
				activeName: "second",
				api: global.baseurl,
				type: 1,
				sm: false,
				yzm: true,
				timer: null,
				state: "",
				subfrom: {
					mobile: "",
					password: "",
					code: "",
				},
				isback: false,
				qrcode: new Array(),
				GVerifyCode: null,
				cbxbox: false
			};
		},
		created() {
			var query = this.$route.query;
			var _this = this;
			if (!isnull(query.type)) {
				_this.type = parseInt(query.type);
				_this.isback = query.isback;
			}
		},
		mounted() {
			//this.creatQrCode();
			this.subfrom.mobile = localStorage.getItem("mobile1");
			this.subfrom.password = localStorage.getItem("password1");
			this.getGVerify();
		},
		beforeDestroy() {
			clearInterval(this.timer);
		},
		methods: {
			getGVerify() {
				this.GVerifyCode = getGVerify({
					id: "canvasCode",
					width: "100",
					height: "40",
					fontSize: "26",
					numSize: 4,
					type: "blend",
				});
			},
			tabType() {
				this.activeName = this.type === 1 ? "first" : "second";
				this.$refs.qrCodeUrl.innerHTML = "";
				this.creatQrCode();
			},
			send() {

			},
			creatQrCode() {
				var _this = this;
				if (_this.timer != null) {
					clearInterval(_this.timer);
				}
				_this.state = _this.getTimestamp();
				// _this.
				try {
					_this.qrcode = new QRCode(_this.$refs.qrCodeUrl, {
						text: _this.api +
							`/apipc/wxlogin?surl=api.lhrc.com.cn&spath=/apipc/wxlogin&state=${_this.state}${_this.type}`, // 需要转换为二维码的内容
						width: 150,
						height: 150,
						colorDark: "#000000",
						colorLight: "#ffffff",
						correctLevel: QRCode.CorrectLevel.H,
					});
					_this.getuserinfo();
				} catch (error) {
					_this.creatQrCode();
				}
			},
			getTimestamp() {
				//把时间日期转成时间戳
				return new Date().getTime();
			},

			getuserinfo() {
				var _this = this;
				this.timer = setInterval(() => {
					_this.Getscanuserinfos();
				}, 3000);
			},
			Getscanuserinfos() {
				var _this = this;
				Getscanuserinfo({
					code: _this.state,
					types: _this.type
				}).then((res) => {
					if (res.success) {
						if (res.data.type === 1) {
							_this.$message({
								message: "扫码成功",
								type: "success",
							});
							//新用户
							clearInterval(_this.timer);
							localStorage.setItem("wxtoken", res.data.rcOpenId);
							_this.resolvewin("/register", {
								type: _this.type
							}, 1);
						} else if (res.data === -1) {
							//暂无扫码
						} else if (res.data.type === 0) {
							_this.$message({
								message: "扫码成功",
								type: "success",
							});
							clearInterval(_this.timer);
							_this.subfrom.mobile = res.data.user[0].peR_ACCOUNT;
							_this.subfrom.password = res.data.user[0].peR_PWD;
							_this.subfrom.type = _this.type;
							_this.logininfo();
							//老用户
						}
					}
				});
			},
			handleClick() {
				this.isDisabled = false;
				this.subfrom.mobile = "";
				this.subfrom.name = "";
				this.subfrom.password = "";
				this.subfrom.repassword = "";
				this.subfrom.code = "";
			},
			smLOGIN: function() {
				this.sm = false;
				this.yzm = true;

				clearInterval(this.timer);
			},
			yzmLOGIN: function() {
				var _this = this;
				setTimeout(() => {
					_this.creatQrCode();
				}, 500);
				this.sm = true;
				this.yzm = false;
			},
			goto(url) {
				this.$router.push(url);
			},
			topage(e) {
				this.$router.push(e);
			},
			logininfo() {
				var _this = this;
				if (isnull(_this.subfrom.mobile)) {
					_this.$message.error("请输入用户名");
					return false;
				}
				if (isnull(_this.subfrom.password)) {
					_this.$message.error("请输入密码");
					return false;
				}
				if (isnull(_this.subfrom.code)) {
					_this.$message.error("请输入验证码");
					return false;
				}
				var flag = this.GVerifyCode.validate(_this.subfrom.code);
				if (!flag) {
					this.$message({
						message: "验证码错误",
						type: "warning",
					});
					this.GVerifyCode.refresh();
					return false;
				}
				var rdatas = new Array();
				UserLogin(_this.subfrom).then((res) => {
					if (res.success) {
						this.$message({
							message: "登录成功",
							type: "success",
						});
						var rdata = res.data;
						rdata.user.userType = _this.type;
						localStorage.setItem("token", rdata.token);
						localStorage.setItem("userinfo", JSON.stringify(rdata.user));
						if (_this.cbxbox) {
							localStorage.setItem("mobile1", _this.subfrom.mobile);
							localStorage.setItem("password1", _this.subfrom.password);
						}
						
						if (_this.isback) {
							setTimeout(_this.$router.go(-1), 500);
						} else if (_this.type == 1) {
							setTimeout(_this.resolvewin("/usercenter?type=0", {}, 1), 500);
						} else {
							setTimeout(_this.resolvewin("/", {}, 1), 500);
						}
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	// tab
	/deep/.el-tabs--card>.el-tabs__header .el-tabs__nav {
		border: none;
		border-radius: 0;
	}

	/deep/.el-tabs--card>.el-tabs__header .el-tabs__item {
		border: none;
	}

	/deep/.el-tabs--card>.el-tabs__header {
		border: none;
		margin: 0;
	}

	/deep/ .el-tabs--card>.el-tabs__header .el-tabs__nav {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item {
		width: 48%;
		height: 45px;
		text-align: center;
		box-sizing: border-box;
		border-bottom: 1px solid #fff;
		font-size: 18px;
	}

	/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
		color: #276bf2;
		border-bottom: 1px solid #276bf2;
	}

	/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item:hover {
		color: #276bf2;
	}

	// 内容
	.login {
		width: 100%;
		height: calc(100vh - 125px);
		background: url(../assets/img/login.png) bottom center;
		display: flex;
		align-items: center;
		justify-content: center;

		.contain {
			width: 650px;
			height: 400px;
			display: flex;
			background: white;
			border-radius: 4px;
			overflow: hidden;

			.left {
				box-sizing: border-box;
				width: 234px;
				height: 100%;
				background: #0096FF;
				text-align: center;
				padding: 30px;

				img {
					width: 100%;
				}
			}

			.right {
				width: 416px;
				padding: 48px 48px 0;
				box-sizing: border-box;
				position: relative;

				.scanning {
					.qrcode {
						margin-bottom: 20px;
						display: flex;
						justify-content: center;
						align-content: space-between;
					}

					.radio-group {
						margin-bottom: 10px;
					}

					font-size: 14px;
					color: #999;
					text-align: center;

					img {
						width: 215px;
						margin-top: 40px;
					}

					b {
						color: #276bf2;
					}

					.title {
						font-size: 20px;
						color: #333;
						margin-bottom: 20px;
					}
				}

				.mode1 {
					left: 15px;
				}

				.mode {
					position: absolute;
					top: 15px;
					right: 15px;
					display: flex;
					align-items: center;

					.modeText {
						width: 100px;
						height: 29px;
						color: #999999;
						font-size: 12px;
						line-height: 29px;
						text-align: center;
						margin-left: 15px;
						border-radius: 4px;
						position: relative;
						box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
					}

					img {
						width: 40px;
					}
				}

				.mode:hover {
					cursor: pointer;
				}

				.textIn {
					width: 100%;

					.line {
						width: 100%;
						height: 40px;
						border: 1px solid #ececec;
						box-sizing: border-box;
						margin-top: 25px;
						line-height: 40px;
						padding: 0 15px;
						color: #999;
						display: flex;
						align-items: center;
						position: relative;
						border-radius: 4px;
						background-color: #F5F5F5;
						overflow: hidden;

						.tel {
							font-size: 14px;
							color: #333;
							margin-left: 15px;
						}

						.el-icon-arrow-down {
							font-size: 14px;
							color: #333;
							margin-left: 5px;
						}

						input {
							width: 80%;
							margin-left: 10px;
							font-size: 14px;
							background-color: #F5F5F5;
						}

						.code {
							height: 40px;
							position: absolute;
							right: 0;
						}

						.send {
							font-size: 14px;
							color: #276bf2;
							position: absolute;
							right: 10px;
						}
					}

					.btn {
						width: 100%;
						height: 40px;
						line-height: 40px;
						text-align: center;
						font-size: 14px;
						color: white;
						background: #0096FF;
						margin-top: 15px;
						border-radius: 4px;
						cursor: pointer;
					}

					.free {
						color: #999;
						font-size: 14px;
						margin-top: 15px;
						display: flex;
						justify-content: space-between;
						align-content: center;

						a {
							color: #467DF2
						}
					}

					.free:hover {
						cursor: pointer;
					}
				}

				.tips {
					width: 100%;
					font-size: 12px;
					color: #999;
					text-align: center;
					line-height: 2;
					margin-top: 20px;

					b {
						color: #276bf2;
					}
				}
			}
		}
	}
</style>
